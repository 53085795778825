import { makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    zIndex: 998,
    top: "25%",
    left: "50%",
    marginLeft: "-200px",
    width: "400px",
    height: "300px",
    border: "5px solid white",
    paddingTop: "20px",
    backgroundColor: "#fff",
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  btn: {
    width: "200px",
  },
  formLabel: {
    color: "#000",
    marginBottom: "10px",
    "&.Mui-focused": {
      color: "#000",
    },
  },
  facemContainer: {
    height: "60px",
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
  },
  facemField: {
    background: "#fac61b",
  },
}));
