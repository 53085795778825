import { makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    zIndex: 998,
    top: "30%",
    left: "50%",
    marginLeft: "-200px",
    width: "400px",
    height: "200px",
    border: "5px solid white",
    paddingTop: "20px",
    backgroundColor: "#fff",
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  text: {
    color: "#000",
    marginLeft: "1rem",
  },
  btn: {
    width: "160px",
    marginBottom: "1rem",
  },
  cancelBtn: {
    backgroundColor: "gray",
  },
  btnGroup: {
    width: "400px",
    display: "flex",
    //   flexDirection:'column',
    alignItems: "center",
    justifyContent: "space-around",
  },
}));
