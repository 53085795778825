import { useEffect, useRef, useState } from "react";
import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";

interface IProps {
  loading: boolean;
  roundMessage: string;
  open: boolean;
}

export default function Timer({ loading, roundMessage, open }: IProps) {
  const classes = useStyles();
  const tempRound = useSelector((state: RootState) => state.patient.tempRound);
  const tempPatients = useSelector(
    (state: RootState) => state.patient.tempPatients
  );
  const [timer, setTimer] = useState<{ [key: string]: number }>({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [initSeconds, setInitSeconds] = useState<number>(0);
  const totalSecondsRef = useRef(0);
  const roundTimerRef = useRef("");
  const tempRoundRef = useRef("");

  useEffect(() => {
    if (Object.keys(tempRound).length > 0) {
      const res = window.localStorage.getItem(
        `${tempRound.client} ${tempRound.batch} timer`
      );
      setInitSeconds(res ? JSON.parse(res) : 0);
      roundTimerRef.current = `${tempRound.client} ${tempRound.batch} timer`;
      tempRoundRef.current = `${tempRound.client} ${tempRound.batch}`;
    }
  }, [tempRound]);

  useEffect(() => {
    const storeInterval = setInterval(() => {
      if (Object.keys(tempRound).length > 0) {
        window.localStorage.setItem(
          `${roundTimerRef.current}`,
          JSON.stringify(totalSecondsRef.current)
        );
      }
    }, 15000);
    return () => {
      clearInterval(storeInterval);
    };
  }, [tempRound]);

  useEffect(() => {
    if (!roundMessage && !loading && !open && tempPatients) {
      let totalSeconds = initSeconds;
      const timerInterval = setInterval(() => {
        ++totalSeconds;
        const hour = Math.floor(totalSeconds / 3600);
        const minute = Math.floor((totalSeconds - hour * 3600) / 60);
        const second = totalSeconds - (hour * 3600 + minute * 60);
        const obj = {
          hours: hour,
          minutes: minute,
          seconds: second,
        };
        setTimer(obj);
        totalSecondsRef.current = totalSeconds;
      }, 1000);
      return () => {
        clearInterval(timerInterval);
      };
    }
  }, [loading, roundMessage, initSeconds]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  const handleUnload = (e: any) => {
    const roundData = window.localStorage.getItem(`${tempRoundRef.current}`);
    if (roundData) {
      window.localStorage.setItem(
        `${roundTimerRef.current}`,
        JSON.stringify(totalSecondsRef.current)
      );
    }

    const message = "Changes you made might not be saved";
    (e || window.event).returnValue = message; //Gecko + IE
    return message;
  };
  return (
    <div className={classes.container}>
      {!roundMessage && !loading && !open && (
        <div>
          <span>
            {timer.hours < 10 ? 0 : ""}
            {timer.hours}
          </span>
          :
          <span>
            {timer.minutes < 10 ? 0 : ""}
            {timer.minutes}
          </span>
          :
          <span>
            {timer.seconds < 10 ? 0 : ""}
            {timer.seconds}
          </span>
        </div>
      )}
    </div>
  );
}
