import { makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    zIndex: 998,
    top: "30%",
    left: "50%",
    marginLeft: "-300px",
    width: "600px",
    height: "260px",
    border: "5px solid white",
    padding: "0 20px",
    backgroundColor: "#fff",
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "400px",
      marginLeft: "-200px",
    },
  },
  text: {
    color: "#000",
  },
  btn: {
    padding: "10px 20px",
    marginLeft: "1rem",
    backgroundColor: "#005992",
    color: "#fff",
    border: "none",
    cursor: "pointer",
  },
  cancelBtn: {
    backgroundColor: "gray",
    color: "#fff",
  },
  btnGroup: {
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      minWidth: 120,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  title: {
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
    color: "#000",
  },
  selectField: {
    "& .MuiOutlinedInput-input": {
      padding: 14,
    },
  },
  scrollableMenu: {
    maxHeight: " 200px",
    overflowY: "auto",
  },
}));
