import React from "react";
import { useStyles } from "./styles";
import Button from "@material-ui/core/Button";
import { EndRoundMessage } from "../../Data";
interface IProps {
  handleRoundClose: (type: string) => void;
  endRoundMessage: string;
}
export default function EndRoundPopup({
  handleRoundClose,
  endRoundMessage,
}: IProps) {
  const classes = useStyles();
  console.log("endRoundMessage", endRoundMessage);
  return (
    <div className={classes.root}>
      <div className={classes.text}>{endRoundMessage}</div>
      {endRoundMessage === EndRoundMessage.roundCompleted ||
      endRoundMessage === EndRoundMessage.roundInterrupted ||
      endRoundMessage === EndRoundMessage.roundCancelled ? (
        <div className={classes.btnGroup}>
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            aria-controls="customized-menu"
            aria-haspopup="true"
            onClick={() => handleRoundClose("goAnotherRound")}
          >
            Go to another round
          </Button>
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            aria-controls="customized-menu"
            aria-haspopup="true"
            onClick={() => handleRoundClose("exit")}
          >
            Exit
          </Button>
        </div>
      ) : (
        <Button
          className={classes.btn}
          variant="contained"
          color="primary"
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={() => handleRoundClose("validationFailed")}
        >
          Ok
        </Button>
      )}
    </div>
  );
}
