import React, { useEffect, useState } from "react";
// import TabPanel from '../TabPanel';
import HeadBar from "../HeadBar/HeadBar";
import axios from "axios";
import RoundTable from "../RoundTable/RoundTable";
import BtnGroup from "../BtnGroup/BtnGroup";
import AddPatientForm from "../AddPatientForm/AddPatientForm";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getSessions, getTokenAzure } from "./Function";
import { useStyles } from "./styles";
import EndRoundPopup from "../EndRoundPopup/EndRoundPopup";
import { useAppDispatch } from "../../redux/index";
import {
  setReduxTempRound,
  setReduxFacems,
  setReduxTempPatients,
  setReduxValidationError,
  setReduxNoListRound,
  setReduxFilteredPatients,
} from "../../redux/patient";
import ChangeLocPopup from "../ChangeLocPopup/ChangeLocPopup";
import ChangeFACEMPopup from "../ChangeFACEMPopup/ChangeFACEMPopup";
import WarningPopup from "../WarningPopup/WarningPopup";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { clientsConfig } from "../../Data";
import FilterDialog from "../FilterDialog/FilterDialog";
import DispositionDialog from "../DispositionDialog/DispositionDialog";

const exitMessage = [
  "Your board round work is done for now.",
  "Refresh the page if you need to go back into the tool.",
];

type ContainerProps = {
  userInfo: IUserInfo;
};

const BoardRoundContainer: React.FC<ContainerProps> = ({ userInfo }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  // const [value, setValue] = useState<number>(0);
  const [patients, setPatients] = useState<Array<IData>>([]);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [open, setOpen] = React.useState(true);
  const [endRoundMessage, setEndRoundMessage] = useState<string>("");
  const [clients, setClients] = useState<Array<string>>([]);
  const [popupType, setPopupType] = useState<string>("changeLoc");
  const [roundMessage, setRoundMessage] = useState<string>("");
  const [docPlanType, setDocPlanType] = useState<
    { rn: number | undefined; type: string } | undefined
  >();
  const [warningData, setWarningData] = useState<
    { rn: number | undefined; option: string } | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);
  const [exitBR, setExitBR] = useState<boolean>(false);
  const [addPatients, setAddPatients] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [filterActive, setFilterActive] = useState<boolean>(false);
  const [selectedFacem, setSelectedFacem] = useState<Facem | null>(null);
  const facems = useSelector((state: RootState) => state.patient.facems);
  const tempRound = useSelector((state: RootState) => state.patient.tempRound);
  const noListRound = useSelector(
    (state: RootState) => state.patient.noListRound
  );

  const roundInfo = Object.keys(tempRound).length > 0 ? tempRound : noListRound;

  const handleFilterActive = (status: boolean) => {
    setFilterActive(status);
  };
  const handleClose = () => {
    setOpen(false);
    setPopupType("");
  };
  const handleRoundClose = (type: string) => {
    if (type === "goAnotherRound") {
      setPopupType("changeLoc");
    } else if (type === "exit") {
      setOpen(false);
      setPopupType("");
      setExitBR(true);
    } else if (type === "validationFailed") {
      setOpen(false);
      setPopupType("");
    }
  };
  const showPopup = (type: string, message: string, rn: number) => {
    setOpen(true);
    setPopupType(type);
    if (type === "endRound") {
      setEndRoundMessage(message);
    } else if (type === "docPlan") {
      setDocPlanType({ rn: rn, type: message });
      setSelectedRow(rn);
    } else if (type === "warning") {
      setWarningData({ rn: rn, option: message });
    }
  };

  const handleChangeLoc = (currentClient: string) => {
    console.log("currentClient", currentClient);
    setLoading(true);
    setRoundMessage("");
    setClients([]);
    dispatch(setReduxTempPatients([]));
    dispatch(setReduxFilteredPatients([]));
    dispatch(setReduxTempRound({}));
    dispatch(setReduxNoListRound({}));
    dispatch(
      setReduxValidationError({
        facemError: false,
        commentError: false,
        invalidPatientsRn: [] as number[],
      })
    );
    getTokenAzure()
      .then((token) => {
        getSessions(
          token,
          currentClient,
          setRoundMessage,
          setLoading,
          setPatients,
          setClients,
          dispatch,
          setReduxTempRound,
          setReduxNoListRound,
          setReduxTempPatients,
          setAddPatients,
          setReduxFilteredPatients
        );
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFACEMchange = (e: React.ChangeEvent<{}>, value: Facem | null) => {
    setSelectedFacem(value);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  const handleUnload = (e: any) => {
    const message = "Changes you made might not be saved";
    (e || window.event).returnValue = message; //Gecko + IE
    return message;
  };

  useEffect(() => {
    getTokenAzure()
      .then((token) => {
        const url = "https://br-apim.azure-api.net/medawsuat/getFacems";
        const config = { headers: { Authorization: `Bearer ${token}` } };
        return axios.get(url, config);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .then((res) => {
        if (res) {
          dispatch(setReduxFacems(res.data.facems));
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [dispatch]);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const clientParam = urlParams.get("client");
    clientsConfig.forEach((clientItem) => {
      if (clientParam?.includes(clientItem.code)) {
        setPopupType("changeFACEM");
        handleChangeLoc(clientItem.value);
      }
    });
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const url = `https://medbr2.azurewebsites.net/api/checkRoundStatusUAT?code=${process.env.REACT_APP_AZURE_BATCH_CODE}`;
      const data = {
        client:
          Object.keys(tempRound).length > 0
            ? tempRound.client
            : noListRound.client,
        batch:
          Object.keys(tempRound).length > 0
            ? tempRound.batch
            : noListRound.batch,
      };
      if (data.client && data.batch) {
        axios
          .post(url, data)
          .then((res) => {
            if (res.data.length > 0 && res.data[0].status === "completed") {
              alert(
                "Round is complete. Please close or refresh this tab to continue using the tool. If you continue typing into a completed round, your changes will overwrite any previously submitted round information."
              );
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }, 60000);
    return () => clearInterval(intervalId);
  }, [tempRound, noListRound]);

  return (
    <>
      {exitBR ? (
        <div className={classes.exitBox}>
          <p>{exitMessage[0]}</p>
          <p>{exitMessage[1]}</p>
        </div>
      ) : (
        <div className={classes.root}>
          <HeadBar
            patients={patients}
            setPatients={setPatients}
            showPopup={showPopup}
            clients={clients}
            setLoading={setLoading}
            loading={loading}
            roundMessage={roundMessage}
            open={open}
            userInfo={userInfo}
            selectedFacem={selectedFacem}
            handleFACEMchange={handleFACEMchange}
          />
          {!loading && addPatients && (
            <BtnGroup
              setIsFormOpen={setIsFormOpen}
              open={open}
              setOpen={setOpen}
              showPopup={showPopup}
              filterActive={filterActive}
              handleFilterActive={handleFilterActive}
            />
          )}
          {!loading && !roundMessage && (
            <RoundTable isFormOpen={isFormOpen} showPopup={showPopup} />
          )}
          {roundMessage && (
            <div className={classes.container}>{roundMessage}</div>
          )}
          {loading && (
            <div className={classes.loadingIcon}>
              <CircularProgress />
            </div>
          )}
          <Backdrop className={classes.backdrop} open={open}>
            {isFormOpen && (
              <AddPatientForm
                setIsFormOpen={setIsFormOpen}
                handleClose={handleClose}
                patients={patients}
                setPatients={setPatients}
                setRoundMessage={setRoundMessage}
              />
            )}
            {popupType === "endRound" && (
              <EndRoundPopup
                handleRoundClose={handleRoundClose}
                endRoundMessage={endRoundMessage}
              />
            )}
            {popupType === "changeLoc" && (
              <ChangeLocPopup
                handleChangeLoc={handleChangeLoc}
                handleClose={handleClose}
                handleFACEMchange={handleFACEMchange}
                facems={facems}
                selectedFacem={selectedFacem}
                loading={loading}
              />
            )}
            {popupType === "changeFACEM" && (
              <ChangeFACEMPopup
                handleClose={handleClose}
                handleFACEMchange={handleFACEMchange}
                facems={facems}
                selectedFacem={selectedFacem}
                loading={loading}
              />
            )}
            {popupType === "docPlan" && (
              <DispositionDialog
                handleClose={handleClose}
                open={open}
                rn={selectedRow}
                roundInfo={roundInfo}
                userInfo={userInfo}
                selectedFacem={selectedFacem}
              />
            )}
            {popupType === "warning" && (
              <WarningPopup
                handleClose={handleClose}
                warningData={warningData}
                showPopup={showPopup}
              />
            )}
            {popupType === "filter" && (
              <FilterDialog
                handleClose={handleClose}
                handleFilterActive={handleFilterActive}
              />
            )}
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default BoardRoundContainer;
