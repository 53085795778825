import React from "react";
import { useStyles } from "./styles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";

interface IHeadCell {
  disablePadding: boolean;
  id: keyof IData;
  label: string;
  numeric: boolean;
}

const headCells: IHeadCell[] = [
  { id: "Location", numeric: false, disablePadding: true, label: "Location" },
  { id: "triage", numeric: false, disablePadding: true, label: "Triage Cat" },
  { id: "edlos", numeric: false, disablePadding: true, label: "ED LOS" },
  { id: "URNO", numeric: false, disablePadding: true, label: "URN" },
  { id: "lastname", numeric: false, disablePadding: true, label: "Patient" },
  { id: "gender", numeric: false, disablePadding: true, label: "Sex" },
  { id: "dob", numeric: false, disablePadding: true, label: "DOB" },
  { id: "age", numeric: false, disablePadding: true, label: "Age" },
  {
    id: "Presentation Comment",
    numeric: false,
    disablePadding: true,
    label: "Presentation",
  },
  { id: "Doctor", numeric: false, disablePadding: true, label: "Doctor" },
  {
    id: "triageInformation",
    numeric: false,
    disablePadding: true,
    label: "Triage Info",
  },
  {
    id: "temp",
    numeric: false,
    disablePadding: true,
    label: "Temp",
  },
  {
    id: "hr",
    numeric: false,
    disablePadding: true,
    label: "HR",
  },
  {
    id: "bp",
    numeric: false,
    disablePadding: true,
    label: "BP",
  },
  {
    id: "rr",
    numeric: false,
    disablePadding: true,
    label: "RR",
  },
  {
    id: "sats",
    numeric: false,
    disablePadding: true,
    label: "SATS",
  },
  {
    id: "bgl",
    numeric: false,
    disablePadding: true,
    label: "BGL",
  },
  // {
  //   id: "diagnosis",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Diagnosis",
  // },
  {
    id: "currentMedDS",
    numeric: false,
    disablePadding: true,
    label: "Med DS",
  },
  // {
  //   id: "siteDS",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Site DS",
  // },
];
type Order = "asc" | "desc";

interface IEnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof IData
  ) => void;
  order: Order;
  orderBy: string;
}

export function RoundTableHead({
  classes,
  order,
  orderBy,
  onRequestSort,
}: IEnhancedTableProps) {
  const createSortHandler =
    (property: keyof IData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ padding: "0 16px" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
