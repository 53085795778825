import { Button, ListItemText, withStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { useStyles } from "./styles";
import TextField from "@material-ui/core/TextField";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
    display: "flex",
    flexDirection: "column",
  },
}))(MenuItem);
interface IProps {
  anchorEl: null | HTMLElement;
  handleClick: (event: React.MouseEvent<HTMLElement>, option: string) => void;
  handleClose: () => void;
  selectedItem: string;
  selectedSubItem: string;
  handleSubOptionClick: (option: string) => void;
  handleComentChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  submiteRound: () => void;
  comment: string;
}

export default function EndRoundBtnGroup({
  anchorEl,
  handleClick,
  handleClose,
  selectedItem,
  selectedSubItem,
  handleSubOptionClick,
  handleComentChange,
  submiteRound,
  comment,
}: IProps) {
  const classes = useStyles();
  const validationError = useSelector(
    (state: RootState) => state.patient.validationError
  );
  const btnGroup = ["Round completed", "Round interrupted", "Round cancelled"];
  const roundCancelOptions = ["Cancelled by Site", "Cancelled by Me"];
  return (
    <div className={classes.buttonGroup}>
      {btnGroup.map((btn, i) => {
        return (
          <div
            key={`${i}-${btn}`}
            className={
              validationError.commentError
                ? classes.errButton
                : classes.buttonGroup
            }
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#1377c9",
                fontSize: "12px",
                height: "46px",
                marginLeft: "1em",
              }}
              aria-controls="customized-menu"
              aria-haspopup="true"
              onClick={(e) => handleClick(e, btn)}
            >
              {btn}
            </Button>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              autoFocus={false}
            >
              {selectedItem === "Round cancelled" &&
                roundCancelOptions.map((option, i) => {
                  return (
                    <StyledMenuItem
                      onKeyDown={(e) => e.stopPropagation()}
                      key={`${i}-${option}`}
                      style={{
                        backgroundColor:
                          selectedSubItem === option ? "#1377c9" : "",
                      }}
                      onClick={() => handleSubOptionClick(option)}
                    >
                      <ListItemText
                        style={{
                          color: selectedSubItem === option ? "white" : "black",
                        }}
                        primary={option}
                      />
                    </StyledMenuItem>
                  );
                })}
              <TextField
                value={comment}
                onKeyDown={(e) => e.stopPropagation()}
                className={classes.textField}
                required={selectedItem === "Round completed" ? false : true}
                id="standard-multiline-flexible"
                label={
                  selectedItem === "Round completed"
                    ? "Round comment"
                    : "comment required"
                }
                onChange={(e) => handleComentChange(e)}
                multiline
                rows={4}
                variant="outlined"
                color="primary"
                fullWidth
                InputProps={{
                  endAdornment: <Button onClick={submiteRound}>Submit</Button>,
                }}
              />
            </StyledMenu>
          </div>
        );
      })}
    </div>
  );
}
