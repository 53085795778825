import React, { useEffect, useState } from "react";
import {
  Button,
  DialogTitle,
  TextField,
  InputLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from "@material-ui/core";
import { useStyles } from "./styles";
import Draggable from "react-draggable";
import { Dialog, Paper, PaperProps } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import { updateDiscussedPatient } from "../BoardRoundContainer/Function";
import { RootState } from "../../redux/rootReducer";
import { useAppDispatch } from "../../redux/index";
import {
  setReduxTempPatients,
  setReduxFilteredPatients,
} from "../../redux/patient";
interface IProps {
  handleClose: () => void;
  open: boolean;
  rn: number;
  userInfo: IUserInfo;
  roundInfo: Round;
  selectedFacem: Facem | null;
}

function PaperComponent(props: PaperProps) {
  const nodeRef = React.useRef(null);
  return (
    <Draggable
      nodeRef={nodeRef}
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

export default function DispositionDialog({
  handleClose,
  open,
  rn,
  userInfo,
  selectedFacem,
  roundInfo,
}: IProps) {
  const classes = useStyles();

  const { tempPatients, filteredPatients, tempRound } = useSelector(
    (state: RootState) => state.patient
  );

  const dispatch = useAppDispatch();

  const [currentPlan, setCurrentPlan] = useState<string>("");
  const [currentMedDS, setCurrentMedDS] = useState<string>("");
  const [dialogLoading, setDialogLoading] = useState<boolean>(false);
  const [selectedPatient, setSelectedPatient] = useState<IData | undefined>();
  const [fieldErrors, setFieldErrors] = useState<{
    currentPlan: string;
    currentMedDS: string;
  }>({
    currentPlan: "",
    currentMedDS: "",
  });
  useEffect(() => {
    const patient = tempPatients.filter(
      (temPatient: IData) => temPatient.rn === rn
    );
    setCurrentPlan(patient[0].currentRoundPlan);
    setCurrentMedDS(patient[0].currentMedDS);
    setSelectedPatient(patient[0]);
  }, [rn, tempPatients]);

  const handlePlanCancel = () => {
    handleClose();
  };

  const handlePlanSave = async () => {
    let errors: { currentPlan: string; currentMedDS: string } = {
      currentPlan: "",
      currentMedDS: "",
    };

    if (currentPlan.trim() === "") {
      errors = { ...errors, currentPlan: "Current Plan is required" };
    }

    if (currentMedDS.trim() === "") {
      errors = { ...errors, currentMedDS: "Disposition is required" };
    }

    if (!errors.currentPlan && !errors.currentMedDS) {
      updateDiscussedPatient({
        roundInfo,
        setDialogLoading,
        actualFacem: selectedFacem?.facemNameInTwilio || userInfo?.name || null,
        patientInfo: {
          ...selectedPatient,
          currentRoundPlan: currentPlan,
          currentMedDS: currentMedDS,
        },
      });

      // update patient and filter patient
      const newPatients = tempPatients.map((patient) => {
        if (patient.rn === rn) {
          return {
            ...patient,
            currentRoundPlan: currentPlan,
            currentMedDS: currentMedDS,
          };
        } else {
          return patient;
        }
      });
      dispatch(setReduxTempPatients(newPatients));

      window.localStorage.setItem(
        `${tempRound.client} ${tempRound.batch}`,
        JSON.stringify(newPatients)
      );

      const newFilteredPatients = filteredPatients.map((patient) => {
        if (patient.rn === rn) {
          return {
            ...patient,
            currentRoundPlan: currentPlan,
            currentMedDS: currentMedDS,
          };
        } else {
          return patient;
        }
      });
      dispatch(setReduxFilteredPatients(newFilteredPatients));
      handleClose();
      setFieldErrors({
        currentPlan: "",
        currentMedDS: "",
      });
    } else {
      // There are validation errors, update the error state
      setFieldErrors(errors);
    }
  };

  const getNotes = () => {
    const patient = tempPatients.filter(
      (temPatient: IData) => temPatient.rn === rn
    );
    const { lastRoundNotes } = patient[0];
    let formattedNotes = "";
    if (lastRoundNotes) {
      formattedNotes = lastRoundNotes
        .map(
          ({ batch, previousRoundNotes, previousMedDs }) =>
            `batch: ${batch}\n${previousRoundNotes} ${
              previousRoundNotes ? `- DS - ${previousMedDs}` : ""
            }`
        )
        .join("\n\n");
    }
    return formattedNotes;
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string
  ) => {
    if (type === "currentPlan") {
      setCurrentPlan(e.target.value);

      // Check if the value is empty and set the error message accordingly
    } else if (type === "currentMedDS") {
      setCurrentMedDS(e.target.value);
    }
  };

  return (
    <>
      {tempPatients.length && (
        <Dialog
          open={true}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          maxWidth={"md"}
        >
          {dialogLoading && (
            <div className={classes.loadingIcon}>
              <CircularProgress />
            </div>
          )}
          <div
            className={`${classes.root} ${classes.size}`}
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
          >
            <div className={classes.box}>
              <DialogTitle
                className={classes.summary}
                style={{ cursor: "move", margin: "0" }}
                id="draggable-dialog-title"
              >
                {`${selectedPatient?.firstname} ${selectedPatient?.lastname} ${
                  selectedPatient?.age
                }y.o ${selectedPatient?.gender === "F" ? "Female" : "Male"}`}
              </DialogTitle>
              <section className={classes.row}>
                <InputLabel shrink id="label" className={classes.label}>
                  Triage Information-From Site
                </InputLabel>
                <TextField
                  multiline
                  rows={3}
                  variant="outlined"
                  value={selectedPatient?.triageInformation}
                  disabled
                  style={{ width: "100%", background: "#d9dbdb" }}
                />
              </section>
              <section className={`${classes.row} ${classes.flex}`}>
                Vital Signs:
                <div className={classes.signs}>
                  <span>Temp: {selectedPatient?.temp}</span>
                  <span>HR: {selectedPatient?.hr}</span>
                  <span>BP: {selectedPatient?.bp}</span>
                  <span>RR: {selectedPatient?.rr}</span>
                  <span>SATS: {selectedPatient?.sats}</span>
                  <span>BGL: {selectedPatient?.bgl}</span>
                </div>
              </section>
              <section className={classes.row}>
                <InputLabel shrink id="label" className={classes.label}>
                  Previous Round Notes
                </InputLabel>
                <TextField
                  multiline
                  rows={3}
                  variant="outlined"
                  value={getNotes()}
                  disabled
                  style={{ width: "100%", background: "#d9dbdb" }}
                />
              </section>
              <section className={classes.row}>
                <InputLabel shrink id="label" className={classes.label}>
                  Current Round Plan
                </InputLabel>
                <TextField
                  multiline
                  rows={3}
                  variant="outlined"
                  style={{ width: "100%", background: "#fff" }}
                  value={currentPlan}
                  onChange={(e) => handleChange(e, "currentPlan")}
                  error={Boolean(fieldErrors.currentPlan)}
                  helperText={fieldErrors.currentPlan}
                />
              </section>
              <section className={classes.row}>
                <div>Disposition</div>
                <RadioGroup
                  aria-labelledby="disposition-radio-buttons-group-label"
                  name="radio-buttons-group"
                  className={classes.radioGroup}
                  value={currentMedDS}
                  onChange={(e) => handleChange(e, "currentMedDS")}
                >
                  <FormControlLabel
                    value="Discharge"
                    control={<Radio size="small" />}
                    label={
                      <span className={classes.radioLabel}>Discharge</span>
                    }
                  />
                  <FormControlLabel
                    value="Continue Work Up"
                    control={<Radio size="small" />}
                    label={
                      <span className={classes.radioLabel}>
                        Continue Work Up
                      </span>
                    }
                  />
                  <FormControlLabel
                    value="Decision to Admit"
                    control={<Radio size="small" />}
                    label={
                      <span className={classes.radioLabel}>
                        Decision to Admit
                      </span>
                    }
                  />
                  <FormControlLabel
                    value="Transfer to External Hospital"
                    control={<Radio size="small" />}
                    label={
                      <span className={classes.radioLabel}>
                        Transfer to External Hospital
                      </span>
                    }
                  />
                </RadioGroup>
                {fieldErrors.currentMedDS && (
                  <FormHelperText error>
                    {fieldErrors.currentMedDS}
                  </FormHelperText>
                )}
              </section>
              <div className={classes.btnGroup}>
                <Button
                  className={`${classes.btn} ${classes.cancelBtn}`}
                  variant="contained"
                  color="primary"
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  onClick={handlePlanCancel}
                >
                  cancel
                </Button>
                <Button
                  className={classes.btn}
                  variant="contained"
                  color="primary"
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  onClick={handlePlanSave}
                >
                  save
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
}
