import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import SortIcon from "@material-ui/icons/Sort";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import {
  setReduxFilteredPatients,
  setReduxCurrentPage,
} from "../../redux/patient";
import { useAppDispatch } from "../../redux/index";

interface IProps {
  handleClose: () => void;
  handleFilterActive: (status: boolean) => void;
}
interface IFilteredOptions {
  [key: string]: string[];
}
interface ISelectedFilter {
  [key: string]: string | null;
}

const initialSelectedFilter = {
  Doctor: null,
  Location: null,
  triage: null,
};

export default function FilterDialog({
  handleClose,
  handleFilterActive,
}: IProps) {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const tempPatients = useSelector(
    (state: RootState) => state.patient.tempPatients
  );
  const [filteredOptions, setFilteredOptions] = useState<IFilteredOptions>({
    Doctor: [],
    Location: [],
    triage: [],
  });
  const [selectedFilter, setSelectedFilter] = useState<ISelectedFilter>(
    initialSelectedFilter
  );
  const filterGroup = ["Location", "Doctor", "triage"];
  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name, value } = event.target;
    if (name) {
      setSelectedFilter((prevFilters) => ({
        ...prevFilters,
        [name]: value as string,
      }));
    }
  };
  useEffect(() => {
    const data = [...tempPatients];
    if (data.length === 0) return;
    const Doctor = Array.from(
      data.reduce((accumulator, currentObj) => {
        currentObj.Doctor?.forEach((doctor) => {
          accumulator.add(doctor);
        });
        return accumulator;
      }, new Set<string>())
    );
    const Location = [...new Set(data.map((item) => item.Location))];
    const triage = [...new Set(data.map((item) => item.triage))];
    setFilteredOptions({
      Doctor,
      Location,
      triage,
    });
  }, [tempPatients]);

  const applyFilter = () => {
    const data = [...tempPatients];
    let filteredPatients: IData[] = [...tempPatients];
    if (selectedFilter.Location) {
      filteredPatients = data.filter(
        (item) => item.Location === selectedFilter.Location
      );
    }
    if (selectedFilter.Doctor) {
      filteredPatients = filteredPatients.filter((item) =>
        item.Doctor?.includes(selectedFilter?.Doctor ?? "")
      );
    }
    if (selectedFilter.triage) {
      filteredPatients = filteredPatients.filter(
        (item) => item.triage === selectedFilter.triage
      );
    }
    if (
      selectedFilter.Location ||
      selectedFilter.Doctor ||
      selectedFilter.triage
    ) {
      handleFilterActive(true);
    } else {
      handleFilterActive(false);
    }
    dispatch(setReduxFilteredPatients(filteredPatients));
    dispatch(setReduxCurrentPage(1));
    handleClose();
  };

  return (
    <div className={classes.root}>
      <h3 className={classes.title}>
        Filters
        <SortIcon />
      </h3>
      {filterGroup.map((item, i) => (
        <div key={i} className={classes.formControl}>
          <InputLabel shrink id="label">
            {item.toUpperCase()}
          </InputLabel>
          <Select
            onChange={handleChange}
            id={`demo-simple-select-${item}`}
            labelId={`demo-simple-select-label-${item}`}
            name={item}
            value={selectedFilter[item]}
            variant="outlined"
            fullWidth
            className={classes.selectField}
            MenuProps={{
              classes: { paper: classes.scrollableMenu },
            }}
          >
            {filteredOptions[item].map((element, i) => {
              return (
                <MenuItem key={i} value={element}>
                  {element}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      ))}
      <div className={classes.btnGroup}>
        <button className={classes.btn} onClick={applyFilter}>
          Apply Filters
        </button>
        <button
          className={`${classes.btn} ${classes.cancelBtn}`}
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
