import React from "react";
import TableCell from "@material-ui/core/TableCell";

import { useStyles } from "./styles";

interface IDob {
  dob: string;
}

export const DOBField = ({ dob }: IDob) => {
  const classes = useStyles();

  let formatedDob = "";
  if (dob.includes("-")) {
    formatedDob = dob.split("-").reverse().join("/");
  } else {
    formatedDob = dob;
  }

  return (
    <TableCell width="4%" align="center" padding="none">
      <div>{formatedDob}</div>
    </TableCell>
  );
};
