import { makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    marginTop: "10px",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  facemContainer: {
    height: "60px",
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
  },
  toolBar: {
    width: "100%",
    height: "100%",
    flex: 1,
  },
  barContainer: {
    display: "flex",
    height: "60px",
    paddingRight: "10px",
  },
  button: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  errButton: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    border: "1px solid red",
  },
  tabOne: {
    backgroundColor: "#005992",
    minWidth: "200px",
    color: "#fff",
    height: "46px",
  },
  locationButton: {
    backgroundColor: "#1377c9",
    fontSize: "16px",
    marginLeft: "1rem",
    height: "46px",
    borderRadius: 0,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#1377c9",
    },
  },
  outlinedInput: {
    border: "1px solid red",
    "&.Mui-focused": {
      border: "none",
    },
  },
}));
