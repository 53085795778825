import { useState, useEffect } from "react";
import { useStyles } from "./styles";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";

interface IProps {
  handleClose: () => void;
  selectedFacem: Facem | null;
  handleFACEMchange: (e: any, value: Facem | null) => void;
  facems: Facem[];
  loading: boolean;
}

export default function ChangeFACEMPopup({
  handleClose,
  handleFACEMchange,
  facems,
  selectedFacem,
  loading,
}: IProps) {
  const classes = useStyles();
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const invalidFacem = !selectedFacem?.facemNameInTwilio && facems.length !== 0;

  return (
    <div className={classes.root}>
      {facems.length !== 0 && (
        <>
          <FormLabel classes={{ root: classes.formLabel }} component="legend">
            Please select your name from the list below:
          </FormLabel>

          <div className={classes.facemContainer}>
            <Autocomplete
              id="FACEM"
              options={facems}
              value={selectedFacem}
              getOptionLabel={(option) => option?.facemNameInTwilio}
              onChange={(e, value) => handleFACEMchange(e, value)}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="FACEM:"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </div>
        </>
      )}
      <Tooltip
        placement="top"
        open={invalidFacem && isButtonHovered}
        title="Please select a FACEM to save"
      >
        <div
          onMouseEnter={() => setIsButtonHovered(true)}
          onMouseLeave={() => setIsButtonHovered(false)}
        >
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            aria-controls="save-location-and-facem"
            aria-haspopup="true"
            disabled={loading || invalidFacem}
            onClick={handleClose}
          >
            {facems.length === 0 && !loading ? "Start" : "Save"}
          </Button>
        </div>
      </Tooltip>
    </div>
  );
}
