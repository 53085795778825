import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux";
import {
  setReduxTempPatients,
  setReduxFilteredPatients,
} from "../../redux/patient";
import { useStyles } from "./styles";

interface IProps {
  handleClose: () => void;
  warningData: { rn: number | undefined; option: string } | undefined;
  showPopup: (type: string, message: string, rn: number) => void;
}
export default function WarningPopup({
  handleClose,
  warningData,
  showPopup,
}: IProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const tempPatients = useSelector(
    (state: RootState) => state.patient.tempPatients
  );
  const tempRound = useSelector((state: RootState) => state.patient.tempRound);
  const [option, setOption] = useState<string>("");
  const [rn, setRn] = useState<number>(-1);
  useEffect(() => {
    if (warningData) {
      setOption(warningData.option);
      setRn(warningData.rn || -1);
    }
  }, [warningData]);
  const handleSwitch = () => {
    const planDSelected = tempPatients.filter(
      (patient) => patient.rn === rn && patient.cp === "D"
    );
    let clearModalWindow = false;
    if (option !== "D" && planDSelected.length !== 0) {
      //switch from D to other options
      clearModalWindow = true;
    }
    const newPatients = [];
    for (const tempPatient of tempPatients) {
      if (tempPatient.rn === rn) {
        const obj = {
          ...tempPatient,
          cp: option,
          block: tempPatient.block,
          clinicalImpression: clearModalWindow
            ? ""
            : tempPatient.clinicalImpression,
          treatmentPlan: clearModalWindow ? "" : tempPatient.treatmentPlan,
          investigations: clearModalWindow ? "" : tempPatient.investigations,
          referrals: clearModalWindow ? "" : tempPatient.referrals,
          disposition: clearModalWindow ? "" : tempPatient.disposition,
          comments: clearModalWindow ? "" : tempPatient.comments,
        };
        newPatients.push(obj);
      } else {
        newPatients.push(tempPatient);
      }
    }
    dispatch(setReduxTempPatients(newPatients));
    dispatch(setReduxFilteredPatients(newPatients));
    window.localStorage.setItem(
      `${tempRound.client} ${tempRound.batch}`,
      JSON.stringify(newPatients)
    );
    if (option === "D" || option === "N") {
      showPopup("docPlan", option, rn);
    } else {
      handleClose();
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.text}>
        Are you sure you want to change your selection? If you select “proceed”
        you will lose all data entered for this patient
      </div>
      <div className={classes.btnGroup}>
        <Button
          className={`${classes.btn} ${classes.cancelBtn}`}
          variant="contained"
          color="primary"
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          className={classes.btn}
          variant="contained"
          color="primary"
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={handleSwitch}
        >
          Yes
        </Button>
      </div>
    </div>
  );
}
