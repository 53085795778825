import { makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    // zIndex:1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  container: {
    flexGrow: 1,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "10rem",
    fontSize: "20px",
  },
  loadingIcon: {
    flexGrow: 1,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "20%",
    fontSize: "20px",
  },
  exitBox: {
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));
