interface IMessage {
  roundCompleted: string;
  roundInterrupted: string;
  roundCancelled: string;
  validationFailed: string;
  commentFailed: string;
}
export const EndRoundMessage: IMessage = {
  roundCompleted: "Round completed and submitted!",
  roundInterrupted: "Round interrupted and submitted!",
  roundCancelled: "Round cancelled",
  validationFailed:
    "Required fields have not been completed and round cannot be completed. Please ensure all information has been entered and try again.",
  commentFailed: "Please enter comment!",
};

export const clientsConfig = [
  { name: "Horsham", value: "Horsham-WHCG", code: "Horsham-WHCG" },
  { name: "NHW", value: "NHW", code: "NHW" },
  {
    name: "Bankstown Hospital",
    value: "Bankstown Hospital",
    code: "BR-Bankstown",
  },
  // { name: "Goulburn Valley", value: "GoulburnValleyHealth" },
  // { name: "Katherine", value: "Katherine_Hospital" },
  // { name: "Cessnock", value: "HNE-Cessnock" },
  // { name: "Shellharbour", value: "ISL-Shellharbour" },
  // { name: "Paediatrics", value: "CHS-Paediatrics" },
  // { name: "Tamworth", value: "HNE-Tamworth" },
];
