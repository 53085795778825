import { makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    zIndex: 998,
    top: "25%",
    left: "50%",
    marginLeft: "-200px",
    width: "400px",
    height: "450px",
    border: "5px solid white",
    paddingTop: "20px",
    backgroundColor: "#fff",
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  boxSize: {
    height: "200px",
  },
  text: {
    color: "#000",
  },
  btn: {
    width: "200px",
  },
  formControlLabel: {
    color: "#1377c9",
  },
  radio: {
    "&$checked": {
      color: "#1377c9",
    },
  },
  checked: {},
  formLabel: {
    color: "#000",
    marginBottom: "10px",
    "&.Mui-focused": {
      color: "#000",
    },
  },
  btnGroup: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  },
  btns: {
    width: "160px",
  },
  cancelBtn: {
    backgroundColor: "gray",
    "&:hover": {
      backgroundColor: "gray",
    },
  },
  clientList: {
    width: "300px",
    height: "200px",
    overflow: "auto",
  },
  facemContainer: {
    height: "60px",
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
  },
  facemField: {
    background: "#fac61b",
  },
}));
