import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useStyles } from "./styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";
import {
  setReduxTempPatients,
  setReduxFilteredPatients,
} from "../../redux/patient";
import { RootState } from "../../redux/rootReducer";
import { useAppDispatch } from "../../redux/index";
import moment from "moment";
interface IFormProps {
  setIsFormOpen: (isFormOpen: boolean) => void;
  handleClose: () => void;
  patients: Array<IData>;
  setPatients: (patients: Array<IData>) => void;
  setRoundMessage: (roundMessage: string) => void;
}

export default function AddPatientForm({
  setIsFormOpen,
  handleClose,
  setPatients,
  setRoundMessage,
}: IFormProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const tempPatients = useSelector(
    (state: RootState) => state.patient.tempPatients
  );
  const tempRound = useSelector((state: RootState) => state.patient.tempRound);
  const noListRound = useSelector(
    (state: RootState) => state.patient.noListRound
  );

  const [formValue, setFormValue] = useState({
    lastname: "",
    firstname: "",
    dob: "",
    gender: "",
    Doctor: "",
    "Presentation Comment": "",
    Location: "",
  });
  const [isRequiredFilled, setIsRequiredFilled] = useState<boolean>(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);
  const [isDOBCorrect, setIsDOBCorrect] = useState<boolean>(false);
  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }
    >,
    type: string
  ) => {
    setFormValue({ ...formValue, [type]: event.target.value });
  };
  const AddPatient = async () => {
    // eslint-disable-next-line no-useless-escape
    const pattern = new RegExp(
      // eslint-disable-next-line no-useless-escape
      /^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/\-]\d{4}$/
    );
    let isDOBValid = false;
    if (pattern.test(formValue.dob)) {
      setIsDOBCorrect(true);
      isDOBValid = true;
    } else {
      setIsDOBCorrect(false);
      isDOBValid = false;
    }
    if (
      formValue.firstname &&
      formValue.lastname &&
      formValue.dob &&
      formValue.gender &&
      isDOBValid
    ) {
      const value = formValue["dob"].split("/").reverse().join("-");
      const age = moment().diff(value, "years");
      setIsRequiredFilled(true);
      setIsFormOpen(false);
      handleClose();
      setIsSubmitClicked(true);
      let newPatients = [];
      const obj: IData = {
        Doctor: [formValue.Doctor],
        Location: formValue.Location,
        triage: "",
        "Presentation Comment": formValue["Presentation Comment"],
        URNO: "",
        age: age.toString(),
        batch:
          Object.keys(tempRound).length > 0
            ? tempRound.batch
            : noListRound.batch,
        currentRoundPlan: "",
        block: "",
        client:
          Object.keys(tempRound).length > 0
            ? tempRound.client
            : noListRound.client,
        edlos: "",
        dob: formValue.dob.split("/").reverse().join("-"),
        triageInformation: "",
        temp: "",
        hr: "",
        bp: "",
        rr: "",
        sats: "",
        bgl: "",
        diagnosis: "",
        medDS: "",
        currentMedDS: "",
        siteDS: "",
        firstname: formValue.firstname,
        updatedate: "",
        lastRoundNotes: null,
        reviewNextRound: "",
        significantImpact: "",
        cp: "",
        gender: formValue.gender,
        lastname: formValue.lastname,
        rn: tempPatients.length + 1,
        reviewRequired: "",
        clinicalImpression: "",
        treatmentPlan: "",
        investigations: "",
        referrals: "",
        disposition: "",
        comments: "",
        lastRoundNotesType: "",
        manuallyAdd: "Y",
        reduceTime: "",
      };
      newPatients = [...tempPatients, obj];
      setPatients(newPatients);
      setRoundMessage("");
      dispatch(setReduxTempPatients(newPatients));
      dispatch(setReduxFilteredPatients(newPatients));
      window.localStorage.setItem(
        `${tempRound.client} ${tempRound.batch}`,
        JSON.stringify(newPatients)
      );
    } else {
      setIsRequiredFilled(false);
      setIsSubmitClicked(true);
    }
  };
  const handleCancel = () => {
    setIsFormOpen(false);
    handleClose();
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div className={classes.row}>
        <TextField
          className={classes.textField}
          error={
            !isRequiredFilled && !formValue.lastname && isSubmitClicked
              ? true
              : false
          }
          required
          id="outlined-required"
          label="Surname"
          value={formValue.lastname}
          onChange={(e) => handleChange(e, "lastname")}
          variant="outlined"
          helperText={
            !isRequiredFilled && !formValue.lastname && isSubmitClicked
              ? "lastname is required"
              : ""
          }
        />
        <TextField
          className={classes.textField}
          error={
            !isRequiredFilled && !formValue.firstname && isSubmitClicked
              ? true
              : false
          }
          required
          id="outlined-required"
          label="Firstname"
          value={formValue.firstname}
          onChange={(e) => handleChange(e, "firstname")}
          variant="outlined"
          helperText={
            !isRequiredFilled && !formValue.firstname && isSubmitClicked
              ? "firstname is required"
              : ""
          }
        />
      </div>
      <div className={classes.row}>
        <TextField
          className={classes.textField}
          required
          id="outlined-required"
          label="DOB"
          value={formValue.dob}
          onChange={(e) => handleChange(e, "dob")}
          variant="outlined"
          helperText={
            !isRequiredFilled && !isDOBCorrect && isSubmitClicked
              ? "DOB format required dd/mm/yyyy"
              : "Format: dd/mm/yyyy"
          }
          error={
            !isRequiredFilled && !isDOBCorrect && isSubmitClicked ? true : false
          }
        />
        <FormControl required variant="outlined" className={classes.textField}>
          <InputLabel id="demo-simple-select-outlined-label">Sex</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            error={
              !isRequiredFilled && !formValue.lastname && isSubmitClicked
                ? true
                : false
            }
            label="Sex"
            value={formValue.gender}
            onChange={(e) => handleChange(e, "gender")}
          >
            <MenuItem value="M">Male</MenuItem>
            <MenuItem value="F">Female</MenuItem>
            <MenuItem value="U">Unknown</MenuItem>
          </Select>
          <FormHelperText className={classes.selectHelper}>
            {!isRequiredFilled && !formValue.gender && isSubmitClicked
              ? "gender is required"
              : ""}
          </FormHelperText>
        </FormControl>
      </div>
      <div className={classes.row}>
        <TextField
          className={classes.textField}
          id="outlined-required"
          label="Treating doctor"
          value={formValue.Doctor}
          onChange={(e) => handleChange(e, "Doctor")}
          variant="outlined"
        />
        <TextField
          className={classes.textField}
          id="outlined-required"
          label="Presenting condition"
          value={formValue["Presentation Comment"]}
          onChange={(e) => handleChange(e, "Presentation Comment")}
          variant="outlined"
        />
      </div>
      <div className={classes.row}>
        <TextField
          className={classes.textField}
          id="outlined-required"
          label="Location"
          value={formValue.Location}
          onChange={(e) => handleChange(e, "Location")}
          variant="outlined"
        />
        <TextField className={`${classes.textField} ${classes.hide}`} />
      </div>
      <div className={classes.btnGroup}>
        <Button
          className={classes.btn}
          variant="contained"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className={classes.btn}
          variant="contained"
          color="primary"
          onClick={AddPatient}
        >
          Save
        </Button>
      </div>
    </form>
  );
}
