import React, { useState, useEffect } from "react";
import "./App.css";
import { Provider } from "react-redux";
import { store } from "../src/redux/index";
import BoardRoundContainer from "./components/BoardRoundContainer/BoardRoundContainer";
import { config } from "./Config";
import { PublicClientApplication, LogLevel } from "@azure/msal-browser";
import Button from "@material-ui/core/Button";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  loginContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: config.appId,
    redirectUri: config.redirectUrl,
    authority: config.authority,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: true,
      loggerCallback: (level, message, containsPii) => {
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
});

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const classes = useStyles();
  const [userInfo, setUserInfo] = useState<IUserInfo>({});
  const login = async () => {
    try {
      await publicClientApplication
        .loginPopup({
          scopes: config.scopes,
          prompt: "select_account",
        })
        .then((res) => {
          if (res.idTokenClaims && "groups" in res.idTokenClaims) {
            setIsAuthenticated(true);
            setUserInfo({
              username: res?.account?.username,
              tenantId: res?.account?.tenantId,
              name: res?.account?.name,
            });
          } else {
            alert("You don't have access to BR tool");
          }
        });
    } catch (err) {
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    const currentAccounts = publicClientApplication.getAllAccounts();
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get("name");
    publicClientApplication.setActiveAccount(currentAccounts[0]);
    if (
      currentAccounts.length > 0 &&
      userId &&
      currentAccounts[0].username.includes(userId)
    ) {
      const silentRequest = {
        scopes: ["user.read"],
        account: currentAccounts[0],
        forceRefresh: false,
      };
      publicClientApplication
        .acquireTokenSilent(silentRequest)
        .then((tokenResponse) => {
          if (
            tokenResponse.idTokenClaims &&
            "groups" in tokenResponse.idTokenClaims
          ) {
            setIsAuthenticated(true);
          } else {
            alert("You don't have access to BR tool");
          }
        })
        .catch((error) => {
          setIsAuthenticated(true);
        });
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <Provider store={store}>
      {isAuthenticated ? (
        <BoardRoundContainer userInfo={userInfo} />
      ) : (
        <div className={classes.loginContainer}>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#1377c9" }}
            aria-controls="customized-menu"
            aria-haspopup="true"
            onClick={login}
          >
            Login in to BR Tool
          </Button>
        </div>
      )}
    </Provider>
  );
}

export default App;
