import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { useStyles } from "./styles";
import { RootState } from "../../redux";
import { useSelector } from "react-redux";
import moment from "moment";
import SortIcon from "@material-ui/icons/Sort";
import ClearIcon from "@material-ui/icons/Clear";
import Pagination from "@material-ui/lab/Pagination";
import {
  setReduxCurrentPage,
  setReduxFilteredPatients,
} from "../../redux/patient";
import { useAppDispatch } from "../../redux/index";
import { itemPerPage } from "../../constants/constants";

interface IBtnGroupProps {
  setIsFormOpen: (isFormOpen: boolean) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  filterActive: boolean;
  handleFilterActive: (status: boolean) => void;
  showPopup: (type: string, message: string, rn: number) => void;
}
interface ISummary {
  total: number;
  longLosPatients: number;
  longest: string;
}

export default function BtnGroup({
  setIsFormOpen,
  open,
  setOpen,
  showPopup,
  filterActive,
  handleFilterActive,
}: IBtnGroupProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { tempPatients, filteredPatients, currentPage } = useSelector(
    (state: RootState) => state.patient
  );
  const [summary, setSummary] = useState<ISummary>();
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    if (filteredPatients.length) {
      const calculatedCount = Math.ceil(filteredPatients.length / itemPerPage);
      setCount(calculatedCount);
    }
  }, [filteredPatients]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setReduxCurrentPage(value));
  };

  const getEdlosFormat = (edlos: string) => {
    const hr = edlos.split(":")[0];
    const min = edlos.split(":")[1];
    if (Number(hr) === 0) {
      return `${min} mins`;
    } else {
      return `${Number(hr)}hr ${min}mins`;
    }
  };

  useEffect(() => {
    const total = tempPatients.length;
    const longLosPatients = tempPatients.filter((patient) =>
      moment(patient["edlos"], "HH:mm:ss").isAfter(
        moment("03:00:00", "HH:mm:ss")
      )
    );
    const result =
      tempPatients.length > 0
        ? tempPatients.reduce((a, b) => (a.edlos > b.edlos ? a : b))
        : null;
    const longest = getEdlosFormat(result ? result.edlos : "");
    setSummary({
      total: total,
      longLosPatients: longLosPatients.length,
      longest: longest,
    });
  }, [tempPatients]);

  const handleClearFilter = () => {
    handleFilterActive(false);
    dispatch(setReduxFilteredPatients([...tempPatients]));
    dispatch(setReduxCurrentPage(1));
  };

  const handleClick = () => {
    setIsFormOpen(true);
    setOpen(!open);
  };
  return (
    <div className={classes.container}>
      <div className={classes.leftGroup}>
        <Button
          variant="contained"
          style={{ backgroundColor: "#1377c9", color: "#fff" }}
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          add patient
        </Button>
        <div className={classes.summaryGroup}>
          <Button
            className={classes.summary}
            style={{ color: "#000" }}
            variant="contained"
            disabled
          >{`Total Patients ${summary?.total}`}</Button>
          <Button
            className={classes.summary}
            style={{ color: "#000" }}
            variant="contained"
            disabled
          >{`${
            summary?.longLosPatients
          } Patients with LOS ${">"} 3 hrs`}</Button>
          <Button
            className={classes.summary}
            style={{ color: "#000" }}
            variant="contained"
            disabled
          >{`Longest LOS ${summary?.longest}`}</Button>
        </div>
      </div>
      <Pagination
        count={count}
        siblingCount={0}
        showFirstButton
        showLastButton
        page={currentPage}
        onChange={handleChange}
      />

      <div>
        <Button
          className={classes.filterBtn}
          variant="contained"
          onClick={handleClearFilter}
          disabled={!filterActive}
        >
          <ClearIcon />
          Clear Filter
        </Button>
        <Button
          className={classes.filterBtn}
          variant="contained"
          onClick={() => showPopup("filter", "", -1)}
        >
          <SortIcon />
          Filter
        </Button>
      </div>
    </div>
  );
}
