import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      boxSizing: "border-box",
      padding: "8px 1em 5px",
      display: "flex",
      justifyContent: "space-between",
    },
    summaryGroup: {
      display: "flex",
    },
    summary: {
      marginLeft: "2rem",
    },
    leftGroup: {
      display: "flex",
    },
    filterBtn: {
      backgroundColor: "#1377c9",
      color: "#fff",
      margin: "0 5px",
    },
  })
);
