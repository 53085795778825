import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      zIndex: 999,
      top: "20%",
      left: "50%",
      marginLeft: "-300px",
      width: "600px",
      height: "460px",
      border: "5px solid white",
      paddingTop: "20px",
      backgroundColor: "#fff",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    row: {
      display: "flex",
      justifyContent: "space-around",
    },
    hide: {
      visibility: "hidden",
    },
    btnGroup: {
      display: "flex",
      justifyContent: "center",
    },
    btn: {
      width: "100px",
      margin: "2em",
    },

    textField: {
      width: "42%",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectHelper: {
      color: "red",
    },
  })
);
