import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { clientsConfig as clients } from "../../Data";

interface IProps {
  handleChangeLoc: (currentClient: string) => void;
  handleClose: () => void;
  selectedFacem: Facem | null;
  handleFACEMchange: (e: any, value: Facem | null) => void;
  facems: Facem[];
  loading: boolean;
}

export default function ChangeLocPopup({
  handleChangeLoc,
  handleClose,
  handleFACEMchange,
  facems,
  selectedFacem,
  loading,
}: IProps) {
  const classes = useStyles();
  const [currentClient, setCurrentClient] = useState("");
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const [showChangeLocAlert, setShowChangeLocAlert] = useState<boolean>(false);
  const tempPatients = useSelector(
    (state: RootState) => state.patient.tempPatients
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentClient((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    tempPatients.length > 0
      ? setShowChangeLocAlert(true)
      : setShowChangeLocAlert(false);
  }, [tempPatients]);

  const invalidFacem = !selectedFacem?.facemNameInTwilio && facems.length !== 0;

  return (
    <>
      {showChangeLocAlert ? (
        <div className={`${classes.root} ${classes.boxSize}`}>
          <div className={classes.text}>
            You have unsubmitted information. If you proceed you will lose this
            data. Do you wish to continue changing location?
          </div>
          <div className={classes.btnGroup}>
            <Button
              className={`${classes.btns} ${classes.cancelBtn}`}
              variant="contained"
              color="primary"
              aria-controls="customized-menu"
              aria-haspopup="true"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className={classes.btns}
              variant="contained"
              color="primary"
              aria-controls="customized-menu"
              aria-haspopup="true"
              onClick={() => setShowChangeLocAlert(false)}
            >
              Yes
            </Button>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          {facems.length !== 0 && (
            <>
              <FormLabel
                classes={{ root: classes.formLabel }}
                component="legend"
              >
                Please select your name from the list below:
              </FormLabel>

              <div className={classes.facemContainer}>
                <Autocomplete
                  id="FACEM"
                  options={facems}
                  value={selectedFacem}
                  getOptionLabel={(option) => option?.facemNameInTwilio}
                  onChange={(e, value) => handleFACEMchange(e, value)}
                  style={{ width: 300 }}
                  // classes={{
                  //   root: validationError.facemError ? classes.outlinedInput : "",
                  // }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="FACEM:"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </div>
            </>
          )}
          <FormLabel classes={{ root: classes.formLabel }} component="legend">
            Which Client are you working with today?
          </FormLabel>
          <div className={classes.clientList}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="client"
                name="client"
                value={currentClient}
                onChange={handleChange}
              >
                {clients.map((client, i) => {
                  return (
                    <FormControlLabel
                      key={i}
                      value={client.value}
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label={
                        <Typography className={classes.formControlLabel}>
                          {client.name}
                        </Typography>
                      }
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </div>
          <Tooltip
            placement="top"
            open={invalidFacem && isButtonHovered}
            title="Please select a FACEM to save"
          >
            <div
              onMouseEnter={() => setIsButtonHovered(true)}
              onMouseLeave={() => setIsButtonHovered(false)}
            >
              <Button
                className={classes.btn}
                variant="contained"
                color="primary"
                aria-controls="save-location-and-facem"
                aria-haspopup="true"
                onClick={() => {
                  handleChangeLoc(currentClient);
                  handleClose();
                }}
                disabled={loading || invalidFacem}
              >
                {facems.length === 0 && !loading ? "Start" : "Save"}
              </Button>
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
}
