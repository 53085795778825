import { createSlice } from "@reduxjs/toolkit";

const patientSlice = createSlice({
  name: "patient",
  initialState: {
    tempPatients: [] as Array<IData>,
    tempRound: {} as Round,
    noListRound: {} as Round,
    facems: [] as Array<Facem>,
    currentPage: 1,
    validationError: {
      facemError: false,
      commentError: false,
      invalidPatientsRn: [] as number[],
    },
    filteredPatients: [] as Array<IData>,
  },
  reducers: {
    setReduxTempPatients: (state, action) => {
      state.tempPatients = action.payload;
    },
    setReduxTempRound: (state, action) => {
      state.tempRound = action.payload;
    },
    setReduxFacems: (state, action) => {
      state.facems = action.payload;
    },
    setReduxValidationError: (state, action) => {
      state.validationError = action.payload;
    },
    setReduxNoListRound: (state, action) => {
      state.noListRound = action.payload;
    },
    setReduxFilteredPatients: (state, action) => {
      state.filteredPatients = action.payload;
    },
    setReduxCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
});
export const {
  setReduxTempPatients,
  setReduxTempRound,
  setReduxFacems,
  setReduxValidationError,
  setReduxNoListRound,
  setReduxFilteredPatients,
  setReduxCurrentPage,
} = patientSlice.actions;
export default patientSlice.reducer;
